@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "OffBit-101";
  src: url("../public/fonts/OffBit-101.otf") format("opentype");
}

@font-face {
  font-family: "OffBit-101-Bold";
  src: url("../public/fonts/OffBit-101Bold.otf") format("openType");
}

@font-face {
  font-family: "OffBit-Regular";
  src: url("../public/fonts/OffBit-Regular.otf") format("openType");
}

@font-face {
  font-family: "OffBit-Bold";
  src: url("../public/fonts/OffBit-Bold.otf") format("openType");
}

@font-face {
  font-family: "OffBit-Dot";
  src: url("../public/fonts/OffBit-Dot.otf") format("openType");
}

@font-face {
  font-family: "Plumpelo";
  src: url("../public/fonts/Plumpelo-Pixelated-2.otf") format("openType");
}

:root {
  background-color: #f1f43b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.scroller {
  display: grid;
  -webkit-mask-image: url(/public/scribble-png.png);
  mask-image: url(/public/scribble-png.png);
  mask-size: 85%;
  mask-position: center;
  mask-repeat: no-repeat;
  & > * {
    grid-area: 1 / 1;
    width: 100%;
  }
  .mask {
    z-index: 2;
  }
  video,
  svg {
    width: 100%;
    height: 100%;
  }
}

.mask {
  mix-blend-mode: difference;
  background: #f1f43b;
}

path {
  fill: none;
  stroke: #000;
  stroke-width: 46px;
  /* @TODO: move to js, safari on iphone not supported yet */
  /* stroke-dasharray: 4400;
  stroke-dashoffset: 4400; */
  /* view-timeline-name: --scribble-path;
  view-timeline-axis: block;
  animation: linear draw both;
  animation-timeline: --scribble-path;
  animation-range: entry 0% cover 50%; */
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
